import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import { unitConverter as uc } from '../../../styles/base';
import { getQueryStringParams } from '../../../utils/browserUtils';
import sanityClient from '../../../utils/sanityClient';

import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import ClientCarouselSection from '../../organisms/ClientCarouselSection/ClientCarouselSection';
import HomePageBanner from '../../molecules/HomePageBanner/HomePageBanner';
import Section from '../../molecules/Section/Section';
import SuitePathSection from '../../organisms/SuitePathSection/SuitePathSection';
import TwoColumnSection from '../../organisms/TwoColumnSection/TwoColumnSection';
import { renderPageSections } from '../renderPageSections';
import useLocalize from '../../../hooks/useLocalize';

const HomePage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.homepage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const homePageProps = {
    id: data.homepage._id,
    metadata: { ...data.homepage._rawRoute, href },
    banner: data.homepage._rawHomePageBanner,
    newsBarSection: data.homepage._rawNewsBarSection,
    sectionsBelowBanner: data.homepage._rawSectionsBelowBanner,
    twoColumnSection: data.homepage._rawTwoColumnSection,
    bubbleCtaSection: data.homepage._rawBubbleCtaSection,
    suitePathSection: data.homepage._rawSuitePathSection,
    clientCarouselSection: data.homepage._rawClientCarouselSection,
    sections: data.homepage._rawSections,
  };

  const [newsBarSection, setNewsBarSection] = useState(
    homePageProps.newsBarSection
  );
  let newsBarId;

  useEffect(() => {
    // check for query param
    const queryParams = getQueryStringParams(window.location.search);
    newsBarId = queryParams.newsbar;

    // don't run groq query if query param isn't present
    if (!newsBarId) return;

    sanityClient
      .fetch(`*[_type=="newsBarSection" && _id  == '${newsBarId}']`)
      .then(newNewsBar => {
        setNewsBarSection(newNewsBar[0]);
      });
  }, []);

  const twoColumnSectionCss = css`
    z-index: 2;
    min-height: ${uc('400px')};
    margin-bottom: ${uc('-50px')};

    .page-wrapper {
      padding: ${uc('0 50px')};

      div div div {
        align-items: flex-start;
        max-width: ${uc('625px')};
      }
    }
  `;

  return (
    <Layout
      locale={locale}
      menuColor="white"
      {...homePageProps.metadata}
      newsBarSection={newsBarSection}
      bubbleCtaData={homePageProps.bubbleCtaSection}
      sanityId={homePageProps.id}
    >
      <HomePageBanner {...homePageProps.banner} />
      <Section>{renderPageSections(homePageProps.sectionsBelowBanner)}</Section>
      {homePageProps.twoColumnSection && homePageProps.twoColumnSection[0] && (
        <TwoColumnSection
          css={twoColumnSectionCss}
          {...homePageProps.twoColumnSection[0]}
        />
      )}
      {!homePageProps.suitePathSection.hide && (
        <SuitePathSection {...homePageProps.suitePathSection} />
      )}
      <ClientCarouselSection {...homePageProps.clientCarouselSection} />
      {renderPageSections(homePageProps.sections)}
      {homePageProps.bubbleCtaSection && (
        <BubbleCtaSection {...homePageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({ homepage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }).isRequired,
};

HomePage.defaultProps = {
  data: {},
  location: { href: '' },
};

export default HomePage;

export const query = graphql`
  query($slug: String) {
    homepage: sanityHomepage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawNewsBarSection(resolveReferences: { maxDepth: 10 })
      _rawHomePageBanner(resolveReferences: { maxDepth: 100 })
      _rawSectionsBelowBanner(resolveReferences: { maxDepth: 100 })
      _rawTwoColumnSection(resolveReferences: { maxDepth: 100 })
      _rawSuitePathSection(resolveReferences: { maxDepth: 100 })
      _rawClientCarouselSection(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
  }
`;
