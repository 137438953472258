import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import Card from '../../atoms/Card/Card';
import SuiteTextBlock from '../../molecules/SuiteTextBlock/SuiteTextBlock';
import {
  breakpoints,
  fontSizes,
  fontWeights,
  pageDimensions,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import Section from '../../molecules/Section/Section';
import Grid from '../../atoms/Grid/Grid';
import sanityImage from '../../../utils/sanityImage';
import Image from '../../atoms/Image/Image';
import Heading3 from '../../atoms/Headings/Heading3/Heading3';
import Link from '../../atoms/Link/Link';
import PageWrapper from '../PageWrapper/PageWrapper';
import OneColumnSection from '../OneColumnSection/OneColumnSection';
import CCCTileSection from '../CCCTileSection/CCCTileSection';

const SuitePathSection = ({
  backgroundImage,
  cccTileSection,
  oneColumnSection,
  sectionStyles,
  suitePathGrayAbove,
  suitePathGrayBelow,
  suitePathItems,
  ...props
}) => {
  const backgroundUrl = sanityImage(backgroundImage)
    .auto('format')
    .width(pageDimensions.rawLargeDesktopWidth)
    .fit('max')
    .url();
  const suitePathSectionCss = css`
    position: relative;
    background-image: url(${backgroundUrl});
    background-repeat: no-repeat;
  `;

  const oneColumnSectionCss = css`
    position: relative;
    z-index: 3 !important;
    margin-bottom: ${uc('100px')};

    div:nth-of-type(1) {
      max-width: ${uc('876px')};
    }

    h2 {
      /* had to add !important to override textblock default
         styles - needs refactor */
      font-size: ${fontSizes.thirtyEight} !important;
    }

    p {
      /* had to add !important to override textblock default
         styles - needs refactor */
      font-weight: ${fontWeights.medium} !important;
      font-size: ${fontSizes.twenty} !important;
    }

    @media (${breakpoints.largeTablet}) {
      margin-bottom: ${uc('50px')};
    }
  `;

  const pageWrapperCss = css`
    position: relative;
  `;

  const grayPathsCss = css`
    position: absolute;
    left: 50%;

    @media (${breakpoints.largeTablet}) {
      display: none;
    }
  `;

  const grayPathAboveCss = [
    css`
      top: ${uc('-10px')};
      transform: translateX(-50%) translateY(-100%);
    `,
    grayPathsCss,
  ];

  const grayPathBelowCss = [
    css`
      bottom: ${uc('-10px')};
      transform: translateX(-50%) translateY(100%);
    `,
    grayPathsCss,
  ];

  const suitePathImageCss = css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @media (${breakpoints.largeTablet}) {
      left: 70%;
      opacity: 0.4;
    }
  `;

  const suitePathHeight = 320;
  const suitePathItemCss = css`
    position: relative;
    height: ${uc(`${suitePathHeight}px`)};
    margin-bottom: ${uc('27px')};

    &:hover,
    &:focus {
      box-shadow: ${shadows.largeHovered};
    }

    &:active {
      box-shadow: ${shadows.largeActive};
    }

    @media (${breakpoints.desktop}) {
      margin: ${uc('auto 0 27px')};
    }
  `;

  const suiteLinkCss = css`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (${breakpoints.largeTablet}) {
      padding: ${uc('52px')};
    }

    @media (${breakpoints.mobile}) {
      padding: ${uc('20px')};
    }
  `;

  const suiteTextBlockWrapperCss = css`
    display: flex;
    flex: 0 0 50%;
    align-items: center;
    overflow-wrap: normal;

    @media (${breakpoints.mobile}) {
      display: block;
      flex: none;
      max-width: 100%;
    }
  `;

  const suiteTextBlockCss = css`
    max-width: ${uc('380px')};

    @media (${breakpoints.mobile}) {
      align-self: flex-end;
    }
  `;

  const elaborationTextCss = css`
    display: flex;
    flex: 0 0 50%;
    justify-content: center;
    align-items: center;

    ${Heading3} {
      max-width: ${uc('344px')};
      margin-bottom: 0;
      font-size: ${fontSizes.thirty};
    }

    @media (${breakpoints.largeTablet}) {
      display: none;
    }
  `;

  const cccTileSectionCss = css`
    z-index: 2;
  `;

  const renderedSuitePathItems = suitePathItems.map(
    ({ _key, headingText, suitePathImage, suiteTextBlock }) => (
      <Card key={_key} css={suitePathItemCss}>
        <Link css={suiteLinkCss} to={suiteTextBlock.suiteType}>
          {suitePathImage && (
            <Image
              css={suitePathImageCss}
              src={sanityImage(suitePathImage)
                .height(suitePathHeight)
                .auto('format')
                .url()}
              alt={suitePathImage.alt}
            />
          )}

          <div css={suiteTextBlockWrapperCss}>
            <SuiteTextBlock css={suiteTextBlockCss} {...suiteTextBlock} />
          </div>
          <div css={elaborationTextCss}>
            <Heading3>{headingText}</Heading3>
          </div>
        </Link>
      </Card>
    )
  );

  return (
    <Grid
      sectionStyles={sectionStyles}
      css={suitePathSectionCss}
      flexDirection="column"
      {...props}
    >
      <OneColumnSection css={oneColumnSectionCss} {...oneColumnSection} />
      <Section>
        <PageWrapper css={pageWrapperCss}>
          {suitePathGrayAbove && (
            <Image
              css={grayPathAboveCss}
              src={sanityImage(suitePathGrayAbove)
                .auto('format')
                .url()}
              alt={suitePathGrayAbove.alt}
            />
          )}
          {suitePathItems && renderedSuitePathItems}
          {suitePathGrayBelow && (
            <Image
              css={grayPathBelowCss}
              src={sanityImage(suitePathGrayBelow)
                .auto('format')
                .url()}
              alt={suitePathGrayBelow.alt}
            />
          )}
        </PageWrapper>
      </Section>
      <Section css={cccTileSectionCss} {...props}>
        <PageWrapper>
          {cccTileSection && <CCCTileSection {...cccTileSection} />}
        </PageWrapper>
      </Section>
    </Grid>
  );
};

SuitePathSection.propTypes = {
  suitePathItems: PropTypes.arrayOf(PropTypes.shape({})),
  backgroundImage: PropTypes.shape({}),
  oneColumnSection: PropTypes.shape({}),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  suitePathGrayAbove: PropTypes.shape({ alt: PropTypes.string }),
  suitePathGrayBelow: PropTypes.shape({ alt: PropTypes.string }),
  cccTileSection: PropTypes.shape({}),
};

SuitePathSection.defaultProps = {
  suitePathItems: null,
  backgroundImage: {},
  oneColumnSection: {},
  sectionStyles: {},
  suitePathGrayAbove: null,
  suitePathGrayBelow: null,
  cccTileSection: {},
};

export default SuitePathSection;
