import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  buttonSpacing,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';

import AngledButton from '../../atoms/Buttons/AngledButton/AngledButton';
import AnimatedButton from '../../atoms/Buttons/AnimatedButton/AnimatedButton';
import Button from '../../atoms/Buttons/Button/Button';
import VideoButton from '../../atoms/Buttons/VideoButton/VideoButton';

import { Heading1 } from '../../atoms/Headings/Headings';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import Text from '../../atoms/Text/Text';

const HomePageBanner = ({
  align,
  backgroundImage,
  buttons,
  description,
  heading,
}) => {
  const twoButtons = buttons.length === 2;
  const padAnimatedBtn =
    buttons.length === 2 && buttons[0]._type === 'animatedButton';
  const longHeading = heading.split(' ').length > 2;

  const sectionBackgroundImage = sanityImage(backgroundImage)
    .auto('format')
    .width(pageDimensions.rawLargeDesktopWidth)
    .fit('max')
    .url();

  const sectionCss = css`
    position: relative;
    height: ${uc('700px')};
    margin-bottom: ${uc('-200px')};
    overflow: hidden;
    text-align: ${align};
    background: no-repeat top center;
    background-image: url(${sectionBackgroundImage});
    background-size: cover;

    @media (${breakpoints.largeTablet}) {
      /* Size is based on US image */
      background-size: ${uc('1500px')};
    }

    @media (${breakpoints.tablet}) {
      margin-bottom: ${uc('-305px')};
    }

    @media (${breakpoints.mobile}) {
      height: ${uc('650px')};
      margin-bottom: ${twoButtons ? `${uc('-68px')}` : `${uc('-128px')}`};
      padding: ${uc('0 20px')};
    }
  `;

  const wrapperCss = css`
    position: relative;
    height: ${uc('385px')};
    margin-top: ${uc('115px')};
    padding: ${uc('0 120px')};

    @media (${breakpoints.largeTablet}) {
      height: ${uc('430px')};
      margin-top: ${uc('70px')};
      padding-top: ${uc('100px')};
    }

    @media (${breakpoints.tablet}) {
      height: ${uc('325px')};
      margin-top: ${uc('130px')};
      padding: ${uc('100px 40px 0')};
    }

    @media (${breakpoints.mobile}) {
      height: ${uc('450px')};
      padding: ${uc('140px 10px 0')};
    }
  `;

  const containerCss = css`
    position: absolute;
    top: 50%;
    ${align === 'center' ? 'left: 50%;' : ''}
    width: 40%;
    margin: ${align === 'center' ? `${uc('0 auto')}` : `${uc('0')}`};
    transform: ${align === 'center'
      ? 'translate(-50%, -50%)'
      : 'translateY(-50%)'};

    @media (${breakpoints.tablet}) {
      width: 80%;
    }
  `;

  // style h1 to look like p for SEO optimization
  const descriptionCss = css`
    margin-bottom: ${uc('20px')};
    color: ${colors.white};
    font-weight: ${fontWeights.normal};
    font-size: 1.1rem;
    font-family: ${fontFamilies.roboto};
    line-height: ${lineHeights.copy};
    letter-spacing: 0;

    @media (${breakpoints.tablet}) {
      font-size: 1.1rem;
    }

    @media (${breakpoints.mobile}) {
      font-size: 1.1rem;
    }
  `;

  // style p to look like h1 for SEO optimization
  const headingCss = css`
    margin-bottom: ${uc('10px')};
    color: ${colors.white};
    font-weight: ${fontWeights.bold};
    font-size: ${longHeading ? fontSizes.thirtyEight : fontSizes.fifty};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.title};
    letter-spacing: 0;
    text-shadow: ${uc('0 3px 20px')} rgba(0, 0, 0, 0.5);

    @media (${breakpoints.tablet}) {
      font-size: ${longHeading ? fontSizes.twentyEight : fontSizes.forty};
      white-space: normal;
    }

    @media (${breakpoints.mobile}) {
      font-size: ${longHeading ? fontSizes.twenty : fontSizes.thirty};
    }
  `;

  const buttonCss = css`
    display: flex;
    justify-content: ${align === 'center' ? 'center' : 'inherit'};

    a {
      margin-bottom: ${uc('7px')};
    }

    @media (${breakpoints.smallDesktopMin}) {
      a:first-of-type {
        ${padAnimatedBtn ? `margin-right: ${uc('40px')};` : null}
      }
    }

    @media (${breakpoints.mobile}) {
      flex-wrap: wrap;
    }

    button,
    a {
      display: inline-block;
      ${buttonSpacing}
    }

    @media (${breakpoints.mobile}) {
      display: block;
      width: 100%;

      button,
      a {
        display: block;
        width: 100%;
        margin-right: 0;
        text-align: left;
      }
    }
  `;

  const renderButtons = () => (
    <div css={buttonCss}>
      {buttons.map(button => {
        switch (button._type) {
          case 'videoButton':
            return (
              <VideoButton key={button._key} {...button}>
                {button.buttonText}
              </VideoButton>
            );
          case 'animatedButton':
            return (
              <AnimatedButton key={button._key} {...button}>
                {button.link.text}
              </AnimatedButton>
            );
          case 'angledButton':
            return <AngledButton key={button._key} {...button} />;
          case 'button':
          default:
            return (
              <Button key={button._key} {...button}>
                {button.text}
              </Button>
            );
        }
      })}
    </div>
  );

  return (
    <section css={sectionCss}>
      <PageWrapper css={wrapperCss}>
        <div css={containerCss}>
          {/* use p styled to look like h1 for SEO optimization */}
          <Text css={headingCss}>{heading}</Text>
          {/* use h1 styled to look like p for SEO optimization */}
          <Heading1 css={descriptionCss}>{description}</Heading1>
          <div>{buttons.length > 0 ? renderButtons() : null}</div>
        </div>
      </PageWrapper>
    </section>
  );
};

HomePageBanner.propTypes = {
  align: PropTypes.string,
  backgroundImage: PropTypes.shape({}),
  buttons: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

HomePageBanner.defaultProps = {
  align: 'left',
  backgroundImage: null,
};

export default HomePageBanner;
